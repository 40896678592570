import Main from "./components/Main";
import NavBar from "./components/Navbar";
import ThemeProvider from "./context/ThemeContext";
import React, {useEffect, useState, createContext} from "react";
import getData from "./Services/db";
import Loading from "./components/Loading";

const DbContext = createContext({
  websiteData: {},
  setWebsiteData: (data) => { }
});


function App() {



//const useDbContext = ()=> useContext(DbContext);
   const [websiteData, setWebsiteData] = useState({});
  const [loading, setLoading]= useState(false);

  useEffect(()=>{
    setLoading(true);

      getData().then((data)=>{
        setLoading(false);
        setWebsiteData(data);
      });

  }, [])

  return (
    <DbContext.Provider value={{websiteData, setWebsiteData}}>
    <ThemeProvider>
      <div className="App ">
       
        {!loading?
          (<div>
        
            <NavBar className="order-2 md:order-1"></NavBar>
           
            <Main className="order-1 md:order-2"></Main>
            </div>):
            <Loading/>
        }
      </div>
      </ThemeProvider>
      </DbContext.Provider>
  );
}

export default App;

export{ DbContext};
