import {FaMobileAlt, FaLaptopCode,FaSitemap} from 'react-icons/fa';


const icon = (title)=>{
  if(title === "App Development")
    return (<FaMobileAlt></FaMobileAlt>);
  else if(title === "Web Development")
    return (<FaLaptopCode></FaLaptopCode>);
  else return (<FaSitemap></FaSitemap>);
}

function ServiceCard({ rate, title, reasons}){

    var reas = [];
    if(reasons)
    reasons.forEach(element => {
      reas.push( <div className="border-solid border-black dark:border-grey border-opacity-5 border-b py-6 flex justify-start items-center w-full"><div className="px-2 text-2xl">🔥</div><p> {element} </p></div>);
    });
    return(
        <div className="group text-center w-full flex flex-col  items-center transition duration-500 ease-in-out transform scale-100 hover:scale-105 shadow-lg hover:shadow-2xl  rounded px-4 pb-4 dark:bg-primary-color">
        <div className="bg-accent-color w-full h-4">
      
        </div>
        <div className="py-8">
         <i className=" text-8xl group-hover:text-accent-color transition-all ease-in-out duration-500"> {icon(title)}</i>
        </div >
        <h4 className="py-4 text-center text-xl font-semibold ">{title}</h4>
        <div className=" py-4 text-2xl">
          <h2 className="price font-bold text-3xl">{rate}</h2>
        </div>
      
        <div className="flex flex-col justify-start items-start text-left text-base font-bold w-full">
      
            
            {reas}
        </div>
       <div className="flex-grow"></div>
      
       
      
        <a className="bg-accent-color w-full mt-4 py-2 rounded font-semibold text-white" href="/#contact">Contact us</a>
      </div>
      
    )


}
export default ServiceCard;