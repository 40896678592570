import DarkMode from "./DarkModeButton";
import Home from "./Home";
import OurTeam from "./OurTeam";
import Services from "./Services";
import { useThemeContext } from "../context/ThemeContext";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

function Main(){
    const {darktheme} =useThemeContext();
    const toogleDarkMode = ()=> darktheme?"dark":"light";
    const toggleBackgroundColor = ()=> darktheme?"bg-darkprimary-color":"bg-white";
    return (
       <div className={`md:ml-20  mb-20 md:mb-0  ${toogleDarkMode()} ${toggleBackgroundColor}`}>
           
            <Home/>
            <Portfolio/>
            <Services/>
            <OurTeam/>
            <Contact/>
            <DarkMode/>
       </div>
    );
}


export default Main;