
const PortfolioCard =({title, description, link, image})=>(
    
    <div className="group text-center flex flex-col  justify-start items-center transition duration-500 ease-in-out transform scale-100 hover:scale-105 shadow-lg hover:shadow-2xl  rounded px-4 pb-4 dark:bg-darkprimary-color">
        <div className="bg-accent-color w-full h-4">
      
        </div>

        <div className=" h-8">
      
      </div>
          <img src={image} className="  rounded-3xl bg-white  shadow-md h-60 w-60  " alt="Lif E-Tracker" />
     
       
        <h4 className="py-4 text-center text-xl font-semibold  ">{title}</h4>
      
      
        <div className="text-center text-sm font-light tracking-widest ">
      <p>
       {description}
      </p>
     
    </div>

    <div className="flex-grow"></div>
       
      
       
      
        <a className="bg-accent-color w-full mt-4 py-2 rounded font-semibold text-white" href={link}>Get Now!</a>
      </div>



);

export default PortfolioCard;