import db from "./firebase";
import {doc, getDoc } from"firebase/firestore";


const getData = async()=>{
    const dataDoc = doc(db, "site-data","data");
    const data = await getDoc(dataDoc);
   
    return data.data();
  
  
  }

  export default getData;