
import React from "react";
import PortfolioCard from "./PortfolioCard";
import { DbContext } from "../App";
// install Swiper components
const Portfolio = () =>{
  const useDb = React.useContext(DbContext);
  const projectsCard =[];


  if(useDb.websiteData.portfolio)
  useDb.websiteData.portfolio.map((item)=>(
    projectsCard.push(<PortfolioCard title={item.title} description={item.description}  image={item.image} link={item.link}></PortfolioCard>)));
  
 

  return(

  <div id="portfolio" className=" w-full flex flex-col justify-center items-center p-4 transition-all   duration-700 ease-in text-black dark:text-white dark:bg-primary-color  ">
    
    <h1 className="p-4 text-2xl  font-semibold">Portfolio</h1>
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 content-center gap-4 w-full ">
    {projectsCard}
    
      
    </div>

    
  </div>
);}




export default Portfolio;