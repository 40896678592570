import { FaMoon, FaSun} from 'react-icons/fa'
import {useThemeContext} from '../context/ThemeContext'



function DarkMode(){

    const {setDarktheme, darktheme} =useThemeContext();
    const handleDarkMode = () => darktheme? setDarktheme(false): setDarktheme(true);

    return <button onClick={handleDarkMode} className="mt-auto z-50 bottom-0 right-0 fixed   flex flex-col   items-center justify-center md:justify-start md:p-5 p-0 text-white   active:bg-darkprimary-color  text-2xl w-16 h-16  mb-24 mr-4 rounded bg-darkprimary-color hover:bg-darkprimary-color dark:bg-accent-color shadow-lg transition duration-500 ease-in-out">
        {darktheme?<FaSun className="transition duration-500 ease-in-out"></FaSun>:<FaMoon className="transition duration-500 ease-in-out"></FaMoon>}
    </button>
}

export default DarkMode;