import { FaPaperPlane, FaWhatsapp, FaEnvelope} from 'react-icons/fa';
import {useThemeContext} from '../context/ThemeContext'


function Contact(){
    const {darktheme} = useThemeContext();
    const toogleDarkMode = ()=> darktheme?"dark":"light";
    return(
       <div id="contact" className="min-h-screen w-full flex flex-col justify-center items-center p-4 transition-all   duration-700 ease-in text-black dark:text-white dark:bg-darkprimary-color ">
           
         
        <div className="  flex flex-col  text-xl font-semibold justify-center items-center    w-full md:w-5/6 bg-gradient-to-br from-accent-color to-primary-color shadow-lg rounded-md">
               

                <div className={`flex justify-evenly w-full ${toogleDarkMode} `}>
                    
                    <div className=" w-3/6 hidden  md:flex justify-center items-center text-white">
                     <FaPaperPlane className=" py-4 w-40 h-40" />
                    </div>

                    <div className="flex  justify-start items-center flex-col w-full md:w-3/6  bg-white dark:bg-primary-color  px-8 pb-8 rounded-md transition-all   duration-700 ease-in " >
                    <div className="bg-accent-color w-full h-4"></div>
                     <p className="py-4">Contact Us</p>   

                        <div className="py-20 flex lg:flex-row flex-col items-center  justify-evenly">

                            
                            <a href="tel:+12462416943" className="flex flex-col justify-center items-center px-4">
                                <FaWhatsapp className="w-20 h-20 p-2"></FaWhatsapp>
                                <p className=" text-base font-light">+1(246) 241 - 6943</p>
                            </a>
                            <a href="mailto:trf.studios246@gmail.com" className="flex flex-col justify-center items-center px-4">
                                <FaEnvelope className="w-20 h-20 p-2"></FaEnvelope>
                                <p className=" text-base font-light">trf.studios246@gmail.com</p>
                            </a>

                        </div> 
                    </div>

                {/*
                        <form className="flex justify-center items-center flex-col w-full md:w-3/6 h-full  bg-white dark:bg-primary-color  px-8 pb-8 rounded-md transition-all   duration-700 ease-in" >
                        <div class="bg-accent-color w-full h-4">
                        </div>
                            <p className="pb-4 pt-8">Contact Us</p>
                            <input className="  placeholder-black dark:placeholder-white placeholder-opacity-70  p-2 pl-4 w-full my-2 border-b-2 border-accent-color  rounded-none  font-normal bg-transparent focus:border-none focus:outline-none" placeholder="Name"/>
                            <input className="  placeholder-black dark:placeholder-white placeholder-opacity-70  p-2 pl-4 w-full my-2 border-b-2 border-accent-color  rounded-none  font-normal bg-transparent focus:outline-none" placeholder="Email"/>
                            <input className="  placeholder-black dark:placeholder-white placeholder-opacity-70  p-2 pl-4 w-full my-2 border-b-2 border-accent-color  rounded-none  font-normal bg-transparent focus:outline-none" placeholder="Subject"/>

      
                            <textarea  rows="4"  className=" placeholder-black dark:placeholder-white placeholder-opacity-70  p-2 pl-4 w-full my-2 border-b-4 border-accent-color  rounded-none  font-normal bg-transparent focus:outline-none " placeholder="Message..."/>

                            <button className="button w-full my-4">Send</button>
                        </form>
                */}

                </div>
        </div>


        </div>
     
    )
}

export default Contact;