import trf from '../TRF.png';



function Loading (){

    return (
        <div className="h-screen w-screen bg-darkprimary-color flex justify-center items-center">
            <img className="lg:order-3 order-1  h-96 w-96   animate-pulse " src={trf} alt="LOGO"></img>

        </div>
    );
}

export default Loading;