import React, { createContext, useState, useContext } from "react";



const ThemeContext = createContext({
    darktheme: false,
    setDarktheme: (theme) => { }
});

const useThemeContext = ()=> useContext(ThemeContext);
const ThemeProvider = ({ children }) => {
    const [darktheme, setDarktheme] = useState(false);
    return (<ThemeContext.Provider value={{ darktheme, setDarktheme }} >
        {children}
    </ThemeContext.Provider>);

}




export  default ThemeProvider;
export {ThemeContext, useThemeContext};