import { initializeApp } from "firebase/app";
import {getFirestore} from"firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxRpra8g_vV0G2bHPVJGx4hC6uxY7BCxY",
  authDomain: "tapps-hosting.firebaseapp.com",
  databaseURL: "https://tapps-hosting-default-rtdb.firebaseio.com",
  projectId: "tapps-hosting",
  storageBucket: "tapps-hosting.appspot.com",
  messagingSenderId: "933786484420",
  appId: "1:933786484420:web:9cab6d426ae1c4eb961391"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



export default db;
