import TeamCard from "./TeamCard";
import React from "react";
import { DbContext } from "../App";

const OurTeam= ()=>{
    const useDb = React.useContext(DbContext);
    const teamCards =[];
  

    if(useDb.websiteData.employees)
    useDb.websiteData.employees.map((item)=>(
      teamCards.push(<TeamCard name = {item.name} description={item.description} skills={item.skills} instagram={item.instagram} linkedin={item.linkedin} github={item.github} photo={item.photo} />)));
    
   
    return(
    <div id="team" className="min-h-screen w-full flex flex-col justify-center items-center p-4 transition-all   duration-700 ease-in text-black dark:text-white dark:bg-primary-color  ">
                 <h1 className="p-4 text-2xl  font-semibold">Our Team</h1>


    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 content-center gap-4 ">

        {teamCards}

    </div>
    </div>
);}

export default OurTeam;