import {FaInstagram,FaGithub, FaLinkedinIn} from 'react-icons/fa';

function generateWidth(percentage){
    if(percentage === 100)
      return "group-hover:w-full";
    else if (percentage >= 90)
      return "group-hover:w-11/12";
      else if (percentage >= 75)
      return "group-hover:w-9/12";
      else if (percentage >= 50)
      return "group-hover:w-6/12";
      else return "group-hover:w-3/12";
}

const PercentageBar =({title, percentage})=>{return(  <div class="flex flex-col px-4 py-1 tracking-widest">
<div className="flex flex-row justify-between">
  <p className="font-semi-bold ">{title}</p>
  <p className="font-light ">{percentage}%</p>
</div>
<div className="h-2 my-2 bg-blue-200 rounded-lg">
  <div className={`bg-blue-400 transition-all   duration-500 ease-linear   w-0 ${generateWidth(percentage)} h-2 rounded-lg`}>
    
  </div>
</div>

</div>)};


const TeamCard =({name, description, photo, instagram, linkedin, github, skills})=>{
  const skillsBar =[];
  if(skills)
  for (const key in skills) {
    
    skillsBar.push(<PercentageBar title={key} percentage={skills[key]}></PercentageBar>)
  }
  return (
    <div className=" flex flex-col justify-start items-center text-center transition duration-500 ease-in-out transform scale-100 hover:scale-105 shadow-lg hover:shadow-2xl  rounded px-4 dark:bg-darkprimary-color group  group-hover:z-50">
    <div className="bg-accent-color w-full h-4 z-0">
          
          </div>
        <div  className="py-5">
          <img className=" rounded-full w-52 h-52 object-cover" src={photo} alt=""/>
        </div >
        <h5 className=" py-3 font-semibold text-lg text-blue-300">{name}</h5>
        
    
        <div className="text-center text-sm font-light tracking-widest ">
          <p>
            {description}
          </p>
         
        </div>

        <div className="flex-grow">

        </div>
        <div className="flex flex-col justify-evenly w-full py-5">
          <h5 className="font-semibold text-lg text-center text-blue-300">Skills</h5>
        {
          skillsBar
        }
      </div>
        <div id='social' className="flex flex-col items-stretch">
          <h5 className="font-semibold text-lg text-center text-blue-300">Socials</h5>
          <div className=" flex flex-row justify-evenly items-center w-full h-auto text-3xl ">
       
            <a href={instagram} className="p-5"><FaInstagram></FaInstagram></a>
            <a href={linkedin} className="p-5"><FaLinkedinIn></FaLinkedinIn></a>
            <a href={github} className="p-5"><FaGithub></FaGithub></a>
    
          </div>
        </div>
        
    
       
    
        
      </div>
    
    );
    
}
export default TeamCard;