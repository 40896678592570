
import trf from '../TRF.png';
import {FaUsers, FaHome, FaCode, FaAddressCard} from 'react-icons/fa'
function NavBar(){

    return (
        <div className="bg-darkprimary-color z-50 fixed bottom-0 m-0 w-screen md:hover:w-64 h-20 md:py-5 md:left-0 md:w-20 md:h-screen group transition-width duration-500 ease-in-out text-white">
            <div  className=" flex flex-row  justify-evenly h-full items-center md:flex-col  md:justify-start   md:items-center ">
                <div className="w-full order-3 md:order-1 flex justify-center items-center">
                     <img className=" md:w-20 md:h-20 w-20 h-20 " src={trf} alt="LOGO"></img>
                    
                </div>          
                <NavBarButton link="#home" order={"md:order-2"} orderSmall={"order-1"}  icon={<FaHome className=" w-8 h-8 "/>} title={"Home"}></NavBarButton>
         
                <NavBarButton link="#team" order={"md:order-3"} orderSmall={"order-2"} icon={<FaUsers className=" w-8 h-8 "/>} title={"Team"}></NavBarButton>
         
                <NavBarButton  link="#services" order={"md:order-4"} orderSmall={"order-4"}  icon={<FaCode className="  w-8 h-8 "/>} title={"Services"}></NavBarButton>
            
            <NavBarButton link="#portfolio"  order={"md:order-5"} orderSmall={"order-5"}   icon={<FaAddressCard className="  w-8 h-8 "/>} title={"Portfolio"}></NavBarButton>

           
           

          
            </div>
        </div>
    );
}


const NavBarButton =  ({icon, title, order, orderSmall, link})=> (
    <a href={link} className={` active cursor-pointer ${orderSmall}  ${order} flex md:flex-row flex-col   items-center justify-center md:justify-start md:p-5 p-0 text-white hover:text-blue-300 active:text-blue-300  transition-all duration-200 ease-in-out text-2xl  w-full h-20 hover:bg-black hover:bg-opacity-10`}>
               <i>{icon}</i>
               <p className="md:hidden md:group-hover:block md:ml-5 font-medium  text-sm md:text-lg transition-all duration-200 ease-in-out">{title}</p>
    </a>
 );


export default NavBar;