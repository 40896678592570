import trf from '../TRF.png';
import { FaYoutube,  FaGithub, FaCode, FaInstagram} from 'react-icons/fa';
import React from 'react';
import { DbContext } from '../App';

function Home(){
    const useDb = React.useContext(DbContext);
    return (
        <div id="home" className="flex lg:flex-row flex-col justify-center items-center  min-h-screen w-full   p-4  transition-all   duration-700 ease-in text-black dark:text-white dark:bg-primary-color ">
 
     
  <div className="order-3 lg:order-1  w-14  flex lg:pr-28 lg:flex-col flex-row justify-center items-start  ">
   <a href={useDb.websiteData.youtube} className="p-4 "> 
    <FaYoutube className="w-6 h-6 hover:text-red-500  transition ease-out duration-200"></FaYoutube>
   </a>
   <a href={useDb.websiteData.instagram} className="p-4"> 
    <FaInstagram className="w-6 h-6 hover:text-blue-500  transition ease-out duration-200"></FaInstagram>
  </a>
  <a href={useDb.websiteData.github} className="p-4"> 
    <FaGithub className="w-6 h-6 hover:text-green-400  transition ease-out duration-200"></FaGithub>

  </a>
    
  
  </div>
  <div className="order-2 flex flex-col justify-start items-start ">
    
  <h1 className="text-3xl font-medium">TRF Studios</h1>
  <h6 className="py-2 text-2xl font-semibold">{useDb.websiteData.quote}</h6>
  <p className="py-5 font-light tracking-widest">{useDb.websiteData.description}</p>
  <a className="button" href="/#contact"><FaCode className="p-1 w-8 h-8"/> Work With Us</a>
  </div>
  
 <img className="lg:order-3 order-1  h-96 w-96 " src={trf} alt="LOGO"></img>
 

    

</div>
    );
}

export default Home;