
import ServiceCard from './ServiceCard';
import React from 'react';


import { DbContext } from "../App";
function Services(){
  const useDb = React.useContext(DbContext);
    const servicesCard =[];


    if(useDb.websiteData.services)
    useDb.websiteData.services.map((item)=>(
        servicesCard.push(<ServiceCard title={item.title} rate={item.rates} reasons={item.reasons}></ServiceCard>)));
    
   
    return(
       <div id="services" className="min-h-screen w-full flex flex-col justify-center items-center p-4 transition-all   duration-700 ease-in text-black dark:text-white dark:bg-darkprimary-color ">
           <h1 className="p-4 text-2xl  font-semibold">Services</h1>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 content-center  gap-4 w-full">
            {servicesCard}
     


        </div>
       </div>
    )
}

export default Services;